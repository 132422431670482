<template>
    <div style="min-height:200px">
        <b-overlay :show="loading_list" :opacity="0.0">      
            <b-card class="mb-3"> 
                <app-view-table  :title="person_name" :loading="loading_list" :isMenuColumn="false" :filters.sync="filters" :items="items" :fields="fields" :isSearch="true" :customColumns="customColumns">
                    <template v-slot:shortcut_name="cell">
                        <app-user-avatar v-if="cell.data.item.first_name != null" :id="cell.data.item.id_history_data_main" :user="getUser(cell.data.item)"/>
                    </template>
                </app-view-table>    
            </b-card>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
    </div>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import AppUserAvatar from '@core/components/AppUserAvatar.vue';

export default {
    
    components: {
        AppUserAvatar
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            id_person_data_main: this.$route.params.id_person_data_main,
            mApplicationAdd: { show: false, args: null, result: 0, return: null },
            loading_filters: false,
            loading_list: false,
            customColumns: ["shortcut_name"],
            person_name: '',
            total_rows: 0,
            filters: {
                page: 1,
                per_page: 10,
                changed: 0,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,

                },
                
                filtering: {                        
                        shortcut_name: {type: 'list', changed: 0, values: [], function: "getPersonalHistoryUserList", parameters: {id_person_data_main: this.$route.params.id_person_data_main }, widthClass:'app-width-min-350'},
                        category_name: {type: 'list', changed: 0, values: [], function: "getPersonalHistoryCategorieList", parameters: {id_person_data_main: this.$route.params.id_person_data_main }, widthClass:'app-width-min-350'},
                        type_name: {type: 'list', changed: 0, values: [], function: "getPersonalHistoryTypeList", parameters: {id_person_data_main: this.$route.params.id_person_data_main }, widthClass:'app-width-min-350'},
                },
                search: "",
                status: 1,
            },
            fields: [
                { key: "shortcut_name", label: this.trans('user',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "shortcut_name"},
                { key: "entry_date", label: this.trans('entry-date',182), thStyle: { "min-width": "170px", "max-width": "170px" }, visible: true },
              
                { key: "category_name", label: this.trans('category',182), thStyle: { "min-width": "150px", "max-width": "150px"}, visible: true, filtering: true, filtering_name: "category_name" },
                { key: "type_name", label: this.trans('type',182), thStyle: { "min-width": "250px", "max-width": "250px" }, visible: true, filtering: true , filtering_name: "type_name"},

                { key: "entry_date", label: this.trans('entry-date',182), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true },
                { key: "description", label: this.trans('description',182), thStyle: { "width": "100%" }, visible: true },
                
            ],
            

            items: [],
        };
    },


    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("people/profile/history/getList", {
                    filters: JSON.stringify(this.filters),
                    id_person_data_main: this.id_person_data_main,
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                    if(res.data.person.inbetween_name){
                        this.person_name = res.data.person.first_name + ' ' + res.data.person.inbetween_name + ' ' + res.data.person.last_name;
                    }else{
                        this.person_name = res.data.person.first_name  + ' ' + res.data.person.last_name;
                    }
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },
        setFormatDate(eventDate) {
            return moment(eventDate).format("DD-MM-YYYY HH:mm"); 
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.shortcut_name.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.category_name.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.type_name.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

        },

        getUser(item){
            return {
                first_name: item.first_name,
                last_name: item.last_name,
                inbetween_name: item.inbetween_name,
                shortcut_name: item.shortcut_name
            }
        }
    },
 
};
</script>